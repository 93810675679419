::ng-deep {
  .i-flex {
    .mat-mdc-text-field-wrapper {
      border-radius: 4px;
      border: none;
      &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-top: 6px;
        padding-bottom: 5px;
      }
    }
    .mat-mdc-form-field-infix {
      min-height: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .mat-mdc-select-arrow-wrapper {
      height: 20px;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      &:not(.mdc-text-field--focused):hover .mdc-line-ripple::before {
        border-bottom-color: transparent;
      }
      width: 100%;
      background-color: #fff;
      .mdc-line-ripple::before {
        border-bottom-color: transparent;
      }
    }
    .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom-style: hidden;
    }
    .mdc-text-field--focused {
      background-color: #fff;
      opacity: 1;
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
      .mdc-evolution-chip__text-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #000;
    }
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
      border-radius: 4px;
      border: 1px solid var(--Black, #000);
      background: #fff;
    }
    .mdc-evolution-chip-set__chips {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .mat-mdc-form-field-type-mat-chip-grid {
      .mdc-line-ripple::before,
      .mdc-line-ripple::after {
        border-bottom-style: none;
      }
    }
  }
  .mat-sort-header-container {
    border: 0 !important;
  }
}

// Overwritting the background color for mat checkbox::ng-deep
.mat-mdc-checkbox {
  &.mat-mdc-checkbox-disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .mdc-checkbox__ripple{
    display: none !important;
  }
  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background-color: #6c4b99 !important;
          border-color: #6c4b99 !important;
        }
      }
    }
  }
  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__native-control:enabled:indeterminate {
        ~ .mdc-checkbox__background {
          background-color: #6c4b99 !important;
          border-color: #6c4b99 !important;
        }
      }
    }
  }
}

.itas-pannel {
  .mat-mdc-option {
    .mat-pseudo-checkbox-checked {
      background: #6c4b99;
    }
  }

  .mat-mdc-option {
    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: #6c4b99;
    }

    .mdc-list-item__primary-text {
      font-family: Poppins;
      word-break: break-word;
    }
  }

}

.itas-pannel-single-select {
  .mat-mdc-option {
    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: #6c4b99;
    }
  }

  .mat-pseudo-checkbox-minimal {
    &.mat-pseudo-checkbox-checked::after {
      color: #6c4b99;
    }
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    font-family: Poppins;
    word-break: break-word;
  }
}

.custom-snackbar {
  &.success {
    .mdc-snackbar__surface {
      border-radius: 4px;
      border: 1px solid #2ba80c;
      background: #f6fff9;
    }
  }
  &.error {
    .mdc-snackbar__surface {
      border-radius: 4px;
      border: 1px solid var(--Red, #da0000);
      background: #fff5f3;
    }
  }
}

.mat-datepicker-popup {
  .mat-calendar-header {
    .mat-mdc-icon-button[disabled] {
      opacity: 0.4;
    }
  }
}

.mat-mdc-slide-toggle{
  .mdc-form-field{
    .mdc-switch{
      width: 56px;
      height: 26px;
      .mdc-switch__track{
        width: 56px;
        height: 26px;
        border-radius: 13px;
      }
      .mdc-switch__handle-track{
        .mdc-switch__handle{
          width: 30px;
          height: 30px;
          border-radius: 15px;
          .mdc-switch__ripple{
            display: none;
          }
        }
      }
      &:not(.mdc-switch--disabled) {
        .mdc-switch__track{
          &::after{
            background: #c095fb !important;
          }
        }
        .mdc-switch__handle-track{
          .mdc-switch__handle{
            width: 30px;
            height: 30px;
            border-radius: 15px;
            &::after{
              background: #535353 !important;
            }
  
            .mdc-switch__ripple{
              display: none;
            }
          }
        }
        &.mdc-switch--selected{
          .mdc-switch__handle{
            &::after{
              background: #6C4B99 !important;
            }
          }
        }
      }
    }
    .mdc-label{
      margin-left: 15px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}