@import "./styles/variables";
@import "./assets/icomoon/icomoon.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./app/shared/material/material.scss";

html {
  overflow: hidden;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

ul {
  padding: 0;
  margin: 0;
  padding-left: 0 !important;

  li {
    list-style: none;
  }
}
