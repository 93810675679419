@font-face {
  font-family: 'irdeto-lib';
  src:  url('fonts/irdeto-lib.eot?cprip7');
  src:  url('fonts/irdeto-lib.eot?cprip7#iefix') format('embedded-opentype'),
    url('fonts/irdeto-lib.ttf?cprip7') format('truetype'),
    url('fonts/irdeto-lib.woff?cprip7') format('woff'),
    url('fonts/irdeto-lib.svg?cprip7#irdeto-lib') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="irdeto-lib-"], [class*=" irdeto-lib-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'irdeto-lib' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.irdeto-lib-insights:before {
  content: "\e900";
}
.irdeto-lib-target-manager:before {
  content: "\e901";
}
.irdeto-lib-chevron:before {
  content: "\e904";
}
.irdeto-lib-security:before {
  content: "\e907";
}
.irdeto-lib-content-management:before {
  content: "\e908";
}
.irdeto-lib-experience-management:before {
  content: "\e909";
}
.irdeto-lib-dashboard:before {
  content: "\e90a";
}
.irdeto-lib-plus:before {
  content: "\e902";
}
.irdeto-lib-subs-manag:before {
  content: "\e905";
}
.irdeto-lib-partners:before {
  content: "\e906";
}
.irdeto-lib-advertisements:before {
  content: "\e90b";
}
.irdeto-lib-trash:before {
  content: "\e90c";
}
.irdeto-lib-edit:before {
  content: "\e90d";
}
.irdeto-lib-view:before {
  content: "\e90e";
}
.irdeto-lib-calendar:before {
  content: "\e90f";
}
.irdeto-lib-back-arrow:before {
  content: "\e910";
}
.irdeto-lib-circle-cross:before {
  content: "\e912";
}
.irdeto-lib-tick:before {
  content: "\e903";
}
.irdeto-lib-search:before {
  content: "\e911";
}
.irdeto-lib-upload:before {
  content: "\e913";
}
.irdeto-lib-bell:before {
  content: "\e915";
}
.irdeto-lib-info:before {
  content: "\e914";
}
.irdeto-lib-player:before {
  content: "\e916";
}
.irdeto-lib-no_image:before {
  content: "\e917";
}
.irdeto-lib-filter:before {
  content: "\e918";
}
.irdeto-lib-menu-option:before {
  content: "\e919";
}
.irdeto-lib-tv-play:before {
  content: "\e91a";
}
.irdeto-lib-rotate:before {
  content: "\e91b";
}
.irdeto-lib-operation:before {
  content: "\e91c";
}
.irdeto-lib-monitoring:before {
  content: "\e91d";
}
.irdeto-lib-control:before {
  content: "\e91e";
}
.irdeto-lib-wm-detection:before {
  content: "\e91f";
}
.irdeto-lib-drm-manager:before {
  content: "\e920";
}
.irdeto-lib-minus:before {
  content: "\e921";
}
.irdeto-lib-esclimination:before {
  content: "\e922";
}
.irdeto-lib-Package-configurator:before {
  content: "\e923";
}
.irdeto-lib-human-task:before {
  content: "\e924";
}
.irdeto-lib-user-management:before {
  content: "\e925";
}
.irdeto-lib-external-link:before {
  content: "\e926";
}
.irdeto-lib-navigation:before {
  content: "\e927";
}
.irdeto-lib-disable:before {
  content: "\e928";
}
.irdeto-lib-user-audit:before {
  content: "\e929";
}
.irdeto-lib-observability:before {
  content: "\e92a";
}
.irdeto-lib-refresh:before {
  content: "\e92b";
}
.irdeto-lib-information:before {
  content: "\e92c";
}